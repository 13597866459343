import React from 'react';
import Featurebox from './Featurebox';

import fimage1 from '../images/11.jpg';
import fimage2 from '../images/22.jpg';
import fimage3 from '../images/33.jpg';
import fimage4 from '../images/44.jpg';


function Feature() {
  return (
    <div id ='features'>
        <h1>SERVICES</h1>
        <div className='a-container'>
<Featurebox image={fimage1} title="Packers & Movers"/>
<Featurebox image={fimage2} title="Office Moving"/>
<Featurebox image={fimage3} title="Corporate Relocation"/>
<Featurebox image={fimage4} title="Residential Moving"/>
        </div>
    </div>
  )
}

export default Feature;