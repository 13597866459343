import React from "react";
import { Helmet } from "react-helmet";  // Import react-helmet
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import Feature from "./Components/Feature";
import Offer from "./Components/Offer";
import About from "./Components/About";
import Contact from "./Components/Contact";

function App() {
  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Packers and Movers in Pakistan | Affordable Home Shifting | Pak Loaders</title>
        <meta
          name="description"
          content="Pak Loaders offers professional Packers and Movers services in Pakistan. Affordable home shifting, reliable moving services. Contact us for stress-free moves."
        />
        <meta
          name="keywords"
          content="Packers and Movers Pakistan, Home Shifting Pakistan, Moving services, Packers, Transport, Pak Loaders"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="App">
        <Navbar />
        <Header />
        <Feature />
        <Offer />
        <About />
        <Contact />
      </div>

      {/* WhatsApp Button */}
      <a 
        href="https://wa.me/923098791041" 
        target="_blank" 
        className="whatsapp-btn" 
        rel="noopener noreferrer"
      >
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" 
          alt="WhatsApp" 
        />
      </a>
    </div>
  );
}

export default App;
