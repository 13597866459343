// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyANfW51izyMjK_msQCkCn3_XfZ9kpycjRY",
  authDomain: "pakloaders-1340c.firebaseapp.com",
  projectId: "pakloaders-1340c",
  storageBucket: "pakloaders-1340c.appspot.com",
  messagingSenderId: "920165874801",
  appId: "1:920165874801:web:3115b2a3141c50d93fafd0",
  measurementId: "G-MJ61X7D0ZG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore database
const db = getFirestore(app);

// Export the Firestore database and analytics
export { db, analytics };
