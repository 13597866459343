import React from 'react';
import { Link } from 'react-scroll';

function Offer() {
  return (
    <div id='offer'>
      <div className='pr-heading'>
        <h1><span>Pakistan's Leading Packers and Movers Platform</span></h1>
        <p className='details'>Effortless Moving Solutions for Homes and Businesses</p>
        <p className='details'>Any Move,</p>
        <p className='details'>Any Time,</p>
        <p className='details'>Anywhere</p>
        <div className='pr-btns'>
          {/* Corrected Link usage */}
          <Link
            to="contact" // The id of the target section (Contact)
            smooth={true} // Enable smooth scroll
            duration={500} // Scroll duration in milliseconds
            className='pr-btn' // Your CSS class for styling the button
          >
            <a1 class="pr-btn">GET A FREE QUOTE</a1>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Offer;
