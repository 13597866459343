import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet

function Header() {
  return (
    <div id='main'> 
      <Helmet>
        <title>Packers and Movers in Multan | Pak Loaders</title>
        <meta 
          name="description" 
          content="Pak Loaders offers reliable and affordable Packers and Movers services in Multan. Move your home with peace of mind. Contact us now!" 
        />
        <meta 
          name="keywords" 
          content="Packers and Movers Multan, Home Shifting Multan, Moving Services Multan, Reliable Packers Multan, Affordable Movers Multan" 
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      
      <div className='name'>
        <h2><span1>PAK LOADERS</span1></h2>
        <h1>Move Your Home with Peace of Mind</h1>
        <p className='details'>
          Don't ask why you should hire us. Just ask yourself why you wouldn't.
        </p>
        <div className='header-btns'>
          <a href="tel:+923098791041" className='header-btn'>Call Us</a>
        </div>
      </div>
    </div>
  )
}

export default Header;
