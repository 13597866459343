import React, { useState } from 'react';
import aboutimage from '../images/about.png';

function About() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const description = `Founded in October 2021, Pak Loaders is Pakistan's leading Packers and Movers platform, offering seamless and reliable moving solutions for residential, office, and corporate relocations. With an extensive transportation network across the country, Pak Loaders ensures hassle-free, timely, and professional moving services, designed to meet the diverse needs of our customers.`;

  const truncatedDescription = description.slice(0, 100); // Show first 100 characters

  return (
    <div id='about'>
      <div className='about-image'>
        <img src={aboutimage} alt=''/>
      </div>
      <div className='about-text'>
        <h1>LEARN MORE ABOUT US</h1>
        <p>
          {isExpanded ? description : truncatedDescription + '...'}
        </p>
        <button onClick={toggleReadMore}>
          {isExpanded ? 'Show Less' : 'Read More'}
        </button>
      </div>
    </div>
  );
}

export default About;
