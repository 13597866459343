
import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from './firebaseConfig';  // Import Firestore database instance

function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'inquiries'), {
        fullName: formData.fullName,
        phoneNumber: formData.phoneNumber,  // Changed from 'email' to 'phoneNumber'
        message: formData.message,
        timestamp: new Date()
      });
      alert('Inquiry submitted successfully!');
      setFormData({ fullName: '', phoneNumber: '', message: '' });  // Clear the form
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('Failed to submit inquiry. Please try again.');
    }
  };


  return (
    <div id='contact'>
      <h1>CONTACT US</h1>
      <div className='header-btns'>
        <a href="tel:+923098791041" className='header-btn1'>+923098791041</a>
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type='text'
          name='fullName'
          placeholder='Full Name'
          value={formData.fullName}
          onChange={handleChange}
          required
        />
        <input
  type='tel'    // Changed input type to 'tel'
  name='phoneNumber'    // Updated field name to 'phoneNumber'
  placeholder='Type Your Phone Number'    // Changed placeholder text
  value={formData.phoneNumber}    // Changed to phoneNumber
  onChange={handleChange}
  required
/>
        <textarea
          name='message'
          placeholder='Write Here....'
          value={formData.message}
          onChange={handleChange}
          required
        />
        <input type='submit' value='Send' />
      </form>
    </div>
  );
}

export default Contact;
